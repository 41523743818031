import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { API_URL } from "../../../actions/urlConfig";
import { Popover } from "antd";
import axios from "axios";
import GridLoader from "react-spinners/GridLoader";
import { MdMoreVert } from "react-icons/md";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import { message, Pagination } from "antd";

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage

const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};

export default class BlogMain extends Component {
  state = {
    visible: false,
    visibleEdit: false,
    visibleImg: false,
    bloglist: [],
    loader: false,
    qArray: [],
    pArray: [],
    mArray: [],
    qp: "none",
    imageArray: [],
    src: null,
    warning: "none",
    crop1: {
      unit: "%",
      width: 50,
      aspect: 1 / 1,
    },
    imageFile: "",
    clicked: false,
    currentPage: 1,
    todosPerPage: 5,
    productlistpagination: [],
    paginationLength: "",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  componentDidMount = () => {
    console.log(admin_token);
    axios
      .get(`${API_URL}/news/view/`)
      .then((res) => {
        console.log(res.data.data, "product list");
        this.setState({ bloglist: res.data.data }, () => {
          const paginationCalc =
            this.state.bloglist.length / this.state.todosPerPage;
          const paginationLength = Math.ceil(paginationCalc * 10);
          this.setState({ paginationLength });
        });
      })
      .catch((er) => {
        console.log(er.response);
      });
  };

  pagination = (pageNumber) => {
    console.log("Page: ", pageNumber);
    this.setState({ pageNumber }, () => {
      const indexOfLastTodo = this.state.pageNumber * this.state.todosPerPage;
      const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
      console.log("Page: ", this.state.currentPage);

      console.log(indexOfFirstTodo, indexOfLastTodo);

      this.setState({
        productlistpagination: this.state.bloglist.slice(
          indexOfFirstTodo,
          indexOfLastTodo
        ),
      });
      console.log(this.state.productlistpagination, "product-list-filter");
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      saveBtn: false,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  deleteTest = (id) => {
    this.setState({ loader: true });
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      headers: { Authorization: `Bearer ${admin_token}` },
    });

    axiosInstance({
      method: `post`,
      url: `${API_URL}/news/delete/${id}/`,
      headers: { Authorization: `Bearer ${admin_token}` },
    })
      .then((res) => {
        this.setState({ loader: false });
        message.success("Service Deleted");
        this.componentDidMount();
      })
      .catch((er) => {
        console.log(er.response, "delete product error");
        this.setState({ loader: false });
      });
  };
  render() {
    return (
      <div
        className="banner-upload p-add-m"
        style={{ height: "auto", paddingBottom: "5vh " }}
      >
        <div className="loader">
          <GridLoader color={"#947D02"} loading={this.state.loader} />
        </div>
        <div className="head-banner">
          {" "}
          <p className="sub-head">Manage service</p>
          <Link to="/news/create" style={{ color: "white" }}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={this.showModal}
            >
              Create service
            </Button>
          </Link>
        </div>

        <table className="testimon-table">
          <tbody className="tm-h">
            <tr>
              <th>Service title</th>
              <th>Date</th>
            </tr>
          </tbody>
          <tbody className="tm-c">
            {this.state.pageNumber === undefined
              ? this.state.bloglist.slice(0, 5).map((res) => (
                  <tr>
                    <td>
                      <Link
                        style={{ color: "#3A9CFF", cursor: "pointer" }}
                        to={`/news/view/${res.id}`}
                      >
                        {res.title}{" "}
                      </Link>
                    </td>
                    <td id="here">{res.date}</td>
                    <Popover
                      placement="right"
                      content={
                        <div
                          className="tm-e"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Link to={`/news/edit/${res.id}`}>
                            <Button
                              variant="contained"
                              onClick={this.showModalEdit}
                              color="default"
                            >
                              Edit
                            </Button>
                          </Link>

                          <Popconfirm
                            placement={"rightBottom"}
                            title="Are you sure？"
                            onConfirm={() => this.deleteTest(res.id)}
                          >
                            <Button variant="contained" color="default">
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      }
                      trigger="click"
                    >
                      <td>
                        <MdMoreVert />
                      </td>{" "}
                    </Popover>
                  </tr>
                ))
              : this.state.productlistpagination.map((res) => (
                  <tr>
                    <td>
                      <Link
                        style={{ color: "#3A9CFF", cursor: "pointer" }}
                        to={`/news/view/${res.id}`}
                      >
                        {res.title}{" "}
                      </Link>
                    </td>
                    <td id="here">{res.date}</td>
                    <Popover
                      placement="right"
                      content={
                        <div
                          className="tm-e"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Link to={`/news/edit/${res.id}`}>
                            <Button
                              variant="contained"
                              onClick={this.showModalEdit}
                              color="default"
                            >
                              Edit
                            </Button>
                          </Link>

                          <Popconfirm
                            placement={"rightBottom"}
                            title="Are you sure？"
                            onConfirm={() => this.deleteTest(res.id)}
                          >
                            <Button variant="contained" color="default">
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      }
                      trigger="click"
                    >
                      <td>
                        <MdMoreVert />
                      </td>{" "}
                    </Popover>
                  </tr>
                ))}
          </tbody>
        </table>
        <Pagination
          defaultCurrent={1}
          total={this.state.paginationLength}
          onChange={this.pagination}
          disabled={this.state.bloglist.length < 5 ? true : false}
        />
      </div>
    );
  }
}
