import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../../actions/urlConfig";
import { Modal, Popover } from "antd";
import Button from "@material-ui/core/Button";
import { MdMoreVert } from "react-icons/md";
import { Popconfirm, notification } from "antd";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage

const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};

export default class FileList extends Component {
  state = {
    tutor: [],
    visible: false,
    visibleEdit: false,
    files: "",
    name: "",
    filename: "",
    loader: false,
    category: [],
    categoryselect: "",
  };
  componentDidMount = () => {
    axios.get(`${API_URL}/files/list/`, config).then((res) => {
      console.log(res, "tutors");
      this.setState({ tutor: res.data.data });
    });
    axios.get(`${API_URL}/files/categoryview/`, config).then((res) => {
      console.log(res.data.data, "files");
      this.setState({ category: res.data.data });
    });
    localStorage.setItem("loc", "5");
    var d = new Date();
    var y = moment(d).format("YYYY-MM-DD");
    this.setState({ currentDate: y });
    console.log(admin_token);
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showModalEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleCancelEdit = (e) => {
    console.log(e);
    this.setState({
      visibleEdit: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOk = () => {
    console.log(this.state.categoryselect, "category");
    this.setState({
      visible: false,
      loader: true,
    });
    const formdata = new FormData();
    formdata.append("name", this.state.name);
    formdata.append("files", this.state.files);
    formdata.append("date", this.state.currentDate);
    if (this.state.categoryselect === "") {
      formdata.append("category", this.state.category[0].id);
    } else {
      formdata.append("category", this.state.categoryselect);
    }

    axios
      .post(`${API_URL}/files/create/`, formdata, config)
      .then((res) => {
        this.setState({ loader: false });
        notification.open({
          message: "Success",
          description: "Added file",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
        this.setState({ name: "" });
      })
      .catch((er) => {
        this.setState({ loader: false });
      });
  };
  handleOkEdit = (id) => {
    this.setState({
      visible: false,
    });
    const formdata = new FormData();

    if (this.state.name !== "") {
      formdata.append("name", this.state.name);
    }
    if (this.state.files !== "") {
      formdata.append("files", this.state.files);
    }
    if (this.state.categoryselect !== "") {
      formdata.append("categry", this.state.categoryselect);
    }
    formdata.append("date", this.state.currentDate);

    axios
      .patch(`${API_URL}/files/update/${id}/`, formdata, config)
      .then((res) => {
        console.log(res, "tutor created");
        notification.open({
          message: "Success",
          description: "Edited the file",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
        this.setState({ name: "" });
        this.setState({
          visibleEdit: false,
        });
      });
  };

  deleteTest = (id) => {
    this.setState({ loader: true });
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      headers: { Authorization: `Bearer ${admin_token}` },
    });

    axiosInstance({
      method: `post`,
      url: `${API_URL}/files/delete/${id}/`,
      headers: { Authorization: `Bearer ${admin_token}` },
    })
      .then((res) => {
        this.setState({ loader: false });

        console.log(res, "deleted product");
        notification.open({
          message: "Success",
          description: "Deleted file",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
      })
      .catch((er) => {
        this.setState({ loader: false });
        notification.open({
          message: "Failed",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
      });
  };

  onChange = (e) => {
    this.setState({
      files: e.target.files[0],
      filename: e.target.files[0].name,
    });
  };

  render() {
    return (
      <div
        className="banner-upload"
        style={{
          height: "auto",
          paddingLeft: "25vw",
          paddingBottom: "2vh ",
          paddingTop: "20vh",
        }}
      >
        <div className="loader">
          <ClipLoader loading={this.state.loader} size={150} />
        </div>
        <div className="head-banner">
          {" "}
          <p className="sub-head">File list</p>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={this.showModal}
          >
            ADD FILE
          </Button>
        </div>
        <table className="testimon-table">
          <tbody className="tm-h">
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Category</th>
            </tr>
          </tbody>
          <tbody className="tm-c">
            {this.state.tutor.map((res) => (
              <tr key={res.id}>
                <td>{res.name}</td>
                <td>{res.date}</td>
                <td>{res.category_name}</td>
                <Popover
                  placement="right"
                  content={
                    <div
                      className="tm-e"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Button
                        variant="contained"
                        onClick={this.showModalEdit}
                        color="default"
                      >
                        Edit
                      </Button>
                      <Modal
                        title="Edit file"
                        visible={this.state.visibleEdit}
                        onOk={() => this.handleOkEdit(res.id)}
                        onCancel={this.handleCancelEdit}
                        width="900px"
                      >
                        {" "}
                        <div className="course-create-modal">
                          <input
                            className="ai"
                            onChange={this.handleChange}
                            type="text"
                            name="name"
                            placeholder="Name"
                            defaultValue={res.name}
                          />
                          <select
                            onChange={(e) => {
                              this.setState({ categoryselect: e.target.value });
                            }}
                            className="ai"
                          >
                            {this.state.category.map((cat) => (
                              <option
                                selected={res.category === cat.id}
                                value={cat.id}
                              >
                                {cat.name}
                              </option>
                            ))}
                          </select>
                          <label
                            className="alt-img-btn-upload file-upload"
                            style={{ width: "unset!important" }}
                          >
                            {this.state.filename === ""
                              ? "CLICK HERE TO UPLOAD"
                              : this.state.filename}
                            <div className="header-up-btn">
                              <input
                                style={{ display: "none" }}
                                className="thread-create-upload"
                                type="file"
                                name="header_image"
                                onChange={this.onChange}
                                required
                              />
                            </div>
                          </label>
                        </div>
                      </Modal>

                      <Popconfirm
                        placement={"rightBottom"}
                        title="Are you sure？"
                        onConfirm={() => this.deleteTest(res.id)}
                      >
                        <Button variant="contained" color="default">
                          Delete
                        </Button>
                      </Popconfirm>
                    </div>
                  }
                  trigger="click"
                >
                  <td>
                    <MdMoreVert />
                  </td>{" "}
                </Popover>
              </tr>
            ))}
            <Modal
              title="Add file"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="700px"
            >
              {" "}
              <div className="course-create-modal">
                <input
                  className="ai"
                  onChange={this.handleChange}
                  type="text"
                  name="name"
                  placeholder="Name"
                />
                <select
                  onChange={(e) => {
                    this.setState({ categoryselect: e.target.value });
                    console.log(e.target.value);
                  }}
                  className="ai"
                >
                  {this.state.category.map((res) => (
                    <option value={res.id}>{res.name}</option>
                  ))}
                </select>
                <label
                  className="alt-img-btn-upload file-upload"
                  style={{ width: "unset!important" }}
                >
                  {this.state.filename === ""
                    ? "CLICK HERE TO UPLOAD"
                    : this.state.filename}
                  <div className="header-up-btn">
                    <input
                      style={{ display: "none" }}
                      className="thread-create-upload"
                      type="file"
                      name="header_image"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </label>
              </div>
            </Modal>
          </tbody>
        </table>
      </div>
    );
  }
}
