import { SET_CURRENT_USER_DATA } from "../actions/authActions";
import isEmpty from "../validation/isEmpty";

const initialState = {
  isAuthenticated: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER_DATA:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),    //  If payload is not empty authenticated
        messages: {}
      };

    default:
      return state;
  }
}
