import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../actions/authActions";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loginErrors: "",
      footer:"flex"
    };
  }

  // Pushing to next page after authentication

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.isAuthenticated) {
      window.location.replace("/blog");
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.onSignIn({ username, password });
  };
  render() {
    return (
      <div className="login container">
        <form className="login-inpt" onSubmit={this.handleSubmit}>
         
          <input
            className=" border-radius font-poppins-regular text-clr input-code"
            placeholder="Username"
            name="username"
            type="text"
            onChange={this.handleChange}
            required
          ></input>
          <input
            className=" border-radius font-poppins-regular text-clr input-code"
            placeholder="Password"
            name="password"
            type="password"
            onChange={this.handleChange}
            required
          ></input>
          <div className="login-btn">
            <button
              type="submit"
              className="btn-code color-code border-radius font-poppins-regular text-clr"
            >
              SIGN IN
            </button>
          </div>
        </form>
        {/* <div className="footer" style={{width:"100%",marginLeft:"0",paddingLeft:"4vh",zIndex:"1000",paddingRight:"4vh"}}>
          <a href="terms">Terms and conditions</a>
          <p>Powered by Techbyheart</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "4vh",
            }}
          >
            <div className="header-language">
              <select
                style={{ width: "" }}
                value={this.state.lang}
                onChange={this.onLangChange}
              >
                <option value="en">English</option>
                <option value="ar">Arabic</option>
              </select>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (userData) => {
      dispatch(signIn(userData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
