import React, { Component } from "react";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import { API_URL } from "../../../actions/urlConfig";
import ReactHtmlParser from "react-html-parser";

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage

const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};

export default class ExpandedBlog extends Component {
  state = {
    blog: [],
  };
  goBack() {
    window.history.back();
  }
  componentDidMount() {
    const blogid = this.props.match.params.blog;

    axios
      .get(`${API_URL}/forum/expand/${blogid}/`, config)
      .then((res) => {
        console.log(res.data.data[0], "product list");
        this.setState({ blog: res.data.data[0] });
      })
      .catch((er) => {
        console.log(er.response);
      });
  }

  render() {
    return (
      <div
        className="banner-upload manage-pro p-add-m"
        style={{
          paddingBottom: "10vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="head-main">
          <div className="rqst-header">
            <IoIosArrowBack onClick={this.goBack} />
            <p className="sub-head">Blog expanded</p>
          </div>
        </div>
        <div className="blog-content">
          <img className="blog-title" src={this.state.blog.title_image}></img>
          <div className="blog-s-content">
            <p className="blog_d">{this.state.blog.date}</p>
            <p className="blog_title">{this.state.blog.title}</p>
            {ReactHtmlParser(this.state.blog.content)}
          </div>
        </div>
      </div>
    );
  }
}
