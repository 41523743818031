import React, { Component } from "react";
import { FiSettings } from "react-icons/fi";
import Logo from "../Images/logo.png";
import { connect } from "react-redux";
import { signOut } from "../../actions/authActions";

class Header extends Component {
  render() {
    return (
      <div className="header">
        <img alt="" src={Logo}></img>
        <div className="header-content" style={{ display: "flex" }}>
       
          <p onClick={this.props.signOut}>Logout</p>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      dispatch(signOut());
    },
  };
};
export default connect(null,mapDispatchToProps)(Header);
