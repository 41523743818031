import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../../actions/urlConfig";
import { Modal, Popover } from "antd";
import Button from "@material-ui/core/Button";
import { MdMoreVert } from "react-icons/md";
import { Popconfirm, notification, Pagination } from "antd";
import moment from "moment";

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage

const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};

export default class EmployeeMain extends Component {
  state = {
    tutor: [],
    visible: false,
    visibleEdit: false,
    title: "",
    date: "",
    link: "",
    currentDate: "",
    currentPage: 1,
    todosPerPage: 5,
    productlistpagination: [],
    paginationLength: "",
  };
  componentDidMount = () => {
    axios.get(`${API_URL}/careers/view/`, config).then((res) => {
      console.log(res, "tutors");
      this.setState({ tutor: res.data.data }, () => {
        const paginationCalc =
          this.state.tutor.length / this.state.todosPerPage;
        const paginationLength = Math.ceil(paginationCalc * 10);
        this.setState({ paginationLength });
      });
    });
    localStorage.setItem("loc", "5");
    var d = new Date();
    var y = moment(d).format("YYYY-MM-DD");
    this.setState({ currentDate: y });
  };

  pagination = (pageNumber) => {
    console.log("Page: ", pageNumber);
    this.setState({ pageNumber }, () => {
      const indexOfLastTodo = this.state.pageNumber * this.state.todosPerPage;
      const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
      console.log("Page: ", this.state.currentPage);

      console.log(indexOfFirstTodo, indexOfLastTodo);

      this.setState({
        productlistpagination: this.state.tutor.slice(
          indexOfFirstTodo,
          indexOfLastTodo
        ),
      });
      console.log(this.state.productlistpagination, "product-list-filter");
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showModalEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleCancelEdit = (e) => {
    console.log(e);
    this.setState({
      visibleEdit: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleChangetime = (e) => {
    console.log(e.target.value);
    this.setState({ exp_date: e.target.value });
  };

  handleOk = () => {
    console.log("clicked");
    this.setState({
      visible: false,
    });
    const formdata = new FormData();
    formdata.append("title", this.state.title);
    formdata.append("date", this.state.currentDate);
    formdata.append("link", this.state.link);
    axios.post(`${API_URL}/careers/create/`, formdata, config).then((res) => {
      console.log(res, "Career created");
      notification.open({
        message: "Success",
        description: "Created career",
        onClick: () => {
          window.location.reload();
          console.log("Notification Clicked!");
        },
      });
      this.componentDidMount();
      this.setState({ title: "", exp_date: "" });
    });
  };
  handleOkEdit = (id) => {
    console.log("clicked");

    const formdata = new FormData();
    if (this.state.title !== "") {
      formdata.append("title", this.state.title);
    }
    formdata.append("date", this.state.currentDate);
    if (this.state.link !== "") {
      formdata.append("link", this.state.link);
    }
    axios
      .patch(`${API_URL}/careers/update/${id}/`, formdata, config)
      .then((res) => {
        console.log(res, "tutor created");
        notification.open({
          message: "Success",
          description: "Updated career",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.setState({ title: "", exp_date: "", link: "" });
        this.componentDidMount();
        this.setState({
          visibleEdit: false,
        });
      });
  };

  deleteTest = (id) => {
    this.setState({ loader: true });
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      headers: { Authorization: `Bearer ${admin_token}` },
    });

    axiosInstance({
      method: `post`,
      url: `${API_URL}/careers/delete/${id}/`,
      headers: { Authorization: `Bearer ${admin_token}` },
    })
      .then((res) => {
        this.setState({ loader: false });

        console.log(res, "deleted product");
        notification.open({
          message: "Success",
          description: "Deleted career",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
      })
      .catch((er) => {
        this.setState({ loader: false });
        notification.open({
          message: "Failed",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
      });
  };
  render() {
    return (
      <div
        className="banner-upload"
        style={{
          height: "auto",
          paddingLeft: "25vw",
          paddingBottom: "2vh ",
          paddingTop: "20vh",
        }}
      >
        {/* <div className="loader">
          <GridLoader color={"#947D02"} loading={this.state.loader} />
        </div> */}
        <div className="head-banner">
          {" "}
          <p className="sub-head">Career list</p>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={this.showModal}
          >
            ADD CAREER
          </Button>
        </div>
        <table className="testimon-table">
          <tbody className="tm-h">
            <tr>
              <th>Title</th>
              <th>Date</th>
              <th>Linkedin url</th>
            </tr>
          </tbody>
          <tbody className="tm-c">
            {this.state.pageNumber === undefined
              ? this.state.tutor.slice(0, 5).map((res) => (
                  <tr key={res.id}>
                    <td>{res.title}</td>
                    <td>{res.date}</td>
                    <td>
                      <a target="#blank" href={res.link}>
                        {res.link}
                      </a>
                    </td>
                    <Popover
                      placement="right"
                      content={
                        <div
                          className="tm-e"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="contained"
                            onClick={this.showModalEdit}
                            color="default"
                          >
                            Edit
                          </Button>
                          <Modal
                            title="Edit career"
                            visible={this.state.visibleEdit}
                            onOk={() => this.handleOkEdit(res.id)}
                            onCancel={this.handleCancelEdit}
                            width="900px"
                          >
                            {" "}
                            <div className="course-create-modal">
                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="text"
                                name="name"
                                placeholder="Name"
                                defaultValue={res.title}
                              />
                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="url"
                                name="link"
                                placeholder="Linkedin url"
                                defaultValue={res.link}
                              />
                            </div>
                          </Modal>

                          <Popconfirm
                            placement={"rightBottom"}
                            title="Are you sure？"
                            onConfirm={() => this.deleteTest(res.id)}
                          >
                            <Button variant="contained" color="default">
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      }
                      trigger="click"
                    >
                      <td>
                        <MdMoreVert />
                      </td>{" "}
                    </Popover>
                  </tr>
                ))
              : this.state.productlistpagination.map((res) => (
                  <tr key={res.id}>
                    <td>{res.title}</td>
                    <td>{res.date}</td>
                    <td>
                      <a target="#blank" href={res.link}>
                        {res.link}
                      </a>
                    </td>
                    <Popover
                      placement="right"
                      content={
                        <div
                          className="tm-e"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="contained"
                            onClick={this.showModalEdit}
                            color="default"
                          >
                            Edit
                          </Button>
                          <Modal
                            title="Edit career"
                            visible={this.state.visibleEdit}
                            onOk={() => this.handleOkEdit(res.id)}
                            onCancel={this.handleCancelEdit}
                            width="900px"
                          >
                            {" "}
                            <div className="course-create-modal">
                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="text"
                                name="name"
                                placeholder="Name"
                                defaultValue={res.title}
                              />
                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="url"
                                name="link"
                                placeholder="Linkedin url"
                                defaultValue={res.link}
                              />
                            </div>
                          </Modal>

                          <Popconfirm
                            placement={"rightBottom"}
                            title="Are you sure？"
                            onConfirm={() => this.deleteTest(res.id)}
                          >
                            <Button variant="contained" color="default">
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      }
                      trigger="click"
                    >
                      <td>
                        <MdMoreVert />
                      </td>{" "}
                    </Popover>
                  </tr>
                ))}
            <Modal
              title="Add career"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="900px"
            >
              {" "}
              <div className="course-create-modal">
                <input
                  className="ai"
                  onChange={this.handleChange}
                  type="text"
                  name="title"
                  placeholder="Title"
                  value={this.state.title}
                />
                <input
                  className="ai"
                  onChange={this.handleChange}
                  type="url"
                  name="link"
                  placeholder="Linkedin url"
                  value={this.state.link}
                />
              </div>
            </Modal>
          </tbody>
        </table>
        <Pagination
          defaultCurrent={1}
          total={this.state.paginationLength}
          onChange={this.pagination}
          disabled={this.state.tutor.length < 5 ? true : false}
        />
      </div>
    );
  }
}
