import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import * as draftToHtml from "draftjs-to-html";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { API_URL } from "../../../actions/urlConfig";
import { message } from "antd";
import moment from "moment";
import { IoIosArrowBack } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage

const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};

export default class CreateBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      uploadedImages: [],
      file: "",
      header_image: "",
      content: "",
      title: "",
      html: "",
      currentDate: "",
      loader: false,
    };
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
  }

  componentDidMount() {
    var d = new Date();
    var y = moment(d).format("YYYY-MM-DD");
    this.setState({ currentDate: y });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  _uploadImageCallBack(file) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }
  uploadMultipleFiles(e) {
    this.fileObj.push(e.target.files);
    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }
    this.setState({ fileArray: this.fileObj });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContentState);
    this.setState({ html });
    // console.log(html, "Editorstate");
  };

  imageUploadCallBack = (file) =>
    new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      // let url = ''
      reader.onload = function (e) {
        img.src = this.result;
      };
      img.onload = function () {
        // console.log(img.src.length)
        // Zoom the canvas needed for the image (you can also define the canvas tag directly in the DOM, so that the compressed image can be directly displayed without going to base64)
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");

        // image original size
        var originWidth = this.width;
        var originHeight = this.height;

        // Maximum size limit, you can achieve image compression by setting the width and height
        var maxWidth = 1600,
          maxHeight = 2000;
        // target size
        var targetWidth = originWidth,
          targetHeight = originHeight;
        // Image size exceeds 300x300 limit
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // wider, size limited by width
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas scales the image
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // clear the canvas
        context.clearRect(0, 0, targetWidth, targetHeight);
        // Image Compression
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        /* The first parameter is the created img object; the second three parameters are the upper left corner coordinates, and the second two are the canvas area width and height */

        //  / / compressed image to base64 url
        /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
         * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
        var newUrl = canvas.toDataURL("image/jpeg", 0.92); //base64 format
        // console.log(newUrl.length)
        // console.log(newUrl,"NEWURL")
        resolve({
          data: {
            link: newUrl,
          },
        });
        //  / / You can also convert the compressed image to blob format for uploading
        // canvas.toBlob((blob)=>{
        //     console.log(blob,"BLOB")
        // // Pass the blob as a parameter to the backend
        // }, 'image/jpeg', 0.92)
      };
    });
  optionClicked(optionsList) {
    this.setState({ multiSelect: optionsList });
  }
  selectedBadgeClicked(optionsList) {
    this.setState({ multiSelect: optionsList });
  }

  onChange = (event) => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      header_image: event.target.files[0],
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitBlog = () => {
    this.setState({ loader: true });
    console.log(this.state.html, "htmlfile");
    console.log(this.state.currentDate, "title");
    console.log(this.state.header_image, "header-image");

    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("title_image", this.state.header_image);
    formData.append("content", this.state.html);
    formData.append("date", this.state.currentDate);

    axios.post(`${API_URL}/news/create/`, formData, config).then((res) => {
      this.setState({ loader: false });
      console.log(res);
      message.success("Service created successfully");
      window.history.back();
    });
  };
  goBack() {
    window.history.back();
  }

  render() {
    return (
      <div
        className="banner-upload manage-pro p-add-m"
        style={{ paddingBottom: "10vh" }}
      >
        <div
          style={{ position: "fixed", top: "50vh", left: "50vw" }}
          className="loader"
        >
          <ClipLoader loading={this.state.loader} size={150} />
        </div>
        <div className="head-main">
          <div className="rqst-header" style={{ paddingLeft: "4vh" }}>
            <IoIosArrowBack onClick={this.goBack} />
            <p className="sub-head">Create service</p>
          </div>

          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={this.submitBlog}
          >
            SAVE
          </Button>
        </div>
        <div>
          <div
            className="thread-create-imagefield"
            style={{
              marginTop: "3em",
              width: "130.60vh",
              height: "auto",
              border: "1px dashed black",
              marginBottom: "50px",
              padding: "5vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              background: "white",
            }}
          >
            {" "}
            <div className="thread-create-imagefield-firstdive">
              <img
                alt=""
                className="uploaded-image-forum"
                src={this.state.file}
              />
            </div>
            <div className="tab-cover-image">
              <label className="alt-img-btn-upload">
                ADD TITLE IMAGE
                {/* <MdFileUpload /> */}
                <div className="header-up-btn">
                  <input
                    style={{ display: "none" }}
                    className="thread-create-upload"
                    type="file"
                    name="header_image"
                    onChange={this.onChange}
                    required
                  />
                </div>
              </label>
            </div>
            <div className="rich-editor-header-image"></div>
          </div>
          <div>
            {/* <input
              placeholder="Thread title"
              className="thread-title"
              onChange={this.handleChange}
              name="title"
              type="text"
            ></input> */}
            <TextField
              id="filled-basic"
              label="Thread title"
              variant="filled"
              name="title"
              onChange={this.handleChange}
            />
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="home-toolbar"
              wrapperClassName="home-wrapper"
              editorClassName="home-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: [
                  "inline",
                  "link",
                  "list",
                  "image",
                  "history",
                  "emoji",
                  "blockType",
                ],
                history: { inDropdown: false },
                inline: { inDropdown: false },
                list: { inDropdown: true },
                textAlign: { inDropdown: false },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: false, // Whether to display the arrange button is equivalent to text-align
                  uploadCallback: this.imageUploadCallBack,
                  previewImage: true,
                  inputAccept: "image/*",
                  alt: { present: false, mandatory: false },
                },
                fontFamily: {
                  options: ["PT Serif"],
                  className: "draft-font",
                  component: undefined,
                  dropdownClassName: undefined,
                },
              }}
              onContentStateChange={this.onEditorContentChange}
              Placeholder="Please lose the article content"
              spellCheck
              Localization={{
                locale: "zh",
                translations: { "generic.add": "add" },
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={this.submitBlog}
          >
            SAVE
          </Button>
        </div>
      </div>
    );
  }
}
