import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../../actions/urlConfig";
import { Modal, Popover, Switch } from "antd";
import Button from "@material-ui/core/Button";
import { MdMoreVert } from "react-icons/md";
import { Popconfirm, notification } from "antd";
import { Select, Pagination } from "antd";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";

import ReactLeafletSearch from "react-leaflet-search";

const { Option } = Select;

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage

const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};

export default class EmployeeMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutor: [],
      visible: false,
      visibleEdit: false,
      username: "",
      password: "",
      email: "",
      name: "",
      countrylist: [],
      phone: "",
      country: "",
      address: "",
      currentDate: "",
      currentPage: 1,
      todosPerPage: 5,
      productlistpagination: [],
      paginationLength: "",
      carIcon: {
        lat: 24.464474109830352,
        lng: 39.61332521261812,
      },
      zoom: 12,
      currentPos: null,
      checkvalue: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ currentPos: e.latlng });
    this.setState({
      latitude: this.state.currentPos.lat,
      longitude: this.state.currentPos.lng,
    });
  }

  componentDidMount = () => {
    console.log(this.state.latitude, "latitude");
    axios.get(`${API_URL}/employee/view/`, config).then((res) => {
      console.log(res.data.data, "surveyors");
      this.setState({ tutor: res.data.data.reverse() }, () => {
        const paginationCalc =
          this.state.tutor.length / this.state.todosPerPage;
        const paginationLength = Math.ceil(paginationCalc * 10);
        this.setState({ paginationLength });
      });
    });
    localStorage.setItem("loc", "5");
    axios.get(`https://restcountries.com/v3.1/all`).then((res) => {
      var country = res.data;
      country.sort(function (a, b) {
        if (a.name.common < b.name.common) {
          return -1;
        }
        if (a.name.common > b.name.common) {
          return 1;
        }
        return 0;
      });
      this.setState({ countrylist: country });
    });
  };

  pagination = (pageNumber) => {
    console.log("Page: ", pageNumber);
    this.setState({ pageNumber }, () => {
      const indexOfLastTodo = this.state.pageNumber * this.state.todosPerPage;
      const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;

      this.setState({
        productlistpagination: this.state.tutor.slice(
          indexOfFirstTodo,
          indexOfLastTodo
        ),
      });
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showModalEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleCancelEdit = (e) => {
    console.log(e);
    this.setState({
      visibleEdit: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOk = () => {
    console.log(this.state.phone, this.state.country);
    this.setState({
      visible: false,
    });
    if (this.state.checked === false) {
      const formdata = new FormData();
      formdata.append("name", this.state.name);
      formdata.append("username", this.state.username);
      formdata.append("email", this.state.email);
      formdata.append("phone", this.state.phone);
      formdata.append("country", this.state.country);
      formdata.append("password", this.state.password);
      axios
        .post(`${API_URL}/employee/create/`, formdata, config)
        .then((res) => {
          console.log(res, "tutor created");
          notification.open({
            message: res.data.message,
            onClick: () => {
              window.location.reload();
              console.log("Notification Clicked!");
            },
          });
          this.componentDidMount();
          this.setState({
            name: "",
            email: "",
            password: "",
            username: "",
            phone: "",
          });
        });
    } else {
      const formdata = new FormData();
      formdata.append("name", this.state.name);
      formdata.append("username", this.state.username);
      formdata.append("email", this.state.email);
      formdata.append("phone", this.state.phone);
      formdata.append("country", this.state.country);
      formdata.append("password", this.state.password);
      formdata.append("contact_us", this.state.checkvalue);
      formdata.append("address", this.state.address);
      formdata.append("latitude", this.state.latitude);
      formdata.append("longitude", this.state.longitude);

      axios
        .post(`${API_URL}/employee/create/`, formdata, config)
        .then((res) => {
          console.log(res, "tutor created");
          notification.open({
            message: res.data.message,
            onClick: () => {
              window.location.reload();
              console.log("Notification Clicked!");
            },
          });
          this.componentDidMount();
          this.setState({
            name: "",
            email: "",
            password: "",
            username: "",
            phone: "",
          });
        });
    }
  };
  handleOkEdit = (id) => {
    const formdata = new FormData();
    if (this.state.name !== "") {
      formdata.append("name", this.state.name);
    }
    if (this.state.email !== "") {
      formdata.append("email", this.state.email);
    }
    if (this.state.phone !== "") {
      formdata.append("phone", this.state.phone);
    }
    axios
      .patch(`${API_URL}/employee/update/${id}/`, formdata, config)
      .then((res) => {
        console.log(res, "tutor created");
        notification.open({
          message: "Success",
          description: "Edited an surveyor",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
        this.setState({
          visibleEdit: false,
        });
        this.setState({
          name: "",
          email: "",
          password: "",
          username: "",
          phone: "",
        });
      });
  };

  deleteTest = (id) => {
    this.setState({ loader: true });
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      headers: { Authorization: `Bearer ${admin_token}` },
    });

    axiosInstance({
      method: `post`,
      url: `${API_URL}/employee/delete/${id}/`,
      headers: { Authorization: `Bearer ${admin_token}` },
    })
      .then((res) => {
        this.setState({ loader: false });

        console.log(res, "deleted product");
        notification.open({
          message: "Success",
          description: "Deleted surveyor",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
      })
      .catch((er) => {
        this.setState({ loader: false });
        notification.open({
          message: "Failed",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
      });
  };
  onChange = (value) => {
    console.log(`selected ${value}`);
    this.setState({ country: value });
  };
  onChangeSwitch = (checked) => {
    console.log(checked);
    this.setState({ checkvalue: checked });
  };
  location = (e) => {
    console.log(e);
    this.setState({
      latitude: e.latLng.lat,
      longitude: e.latLng.lng,
    });
  };
  render() {
    const positionCarIcon = [this.state.carIcon.lat, this.state.carIcon.lng];

    return (
      <div
        className="banner-upload"
        style={{
          height: "auto",
          paddingLeft: "25vw",
          paddingBottom: "2vh ",
          paddingTop: "20vh",
        }}
      >
        {/* <div className="loader">
          <GridLoader color={"#947D02"} loading={this.state.loader} />
        </div> */}
        <div className="head-banner">
          <p className="sub-head">Surveyor list</p>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={this.showModal}
          >
            ADD SURVEYOR
          </Button>
        </div>
        <table className="testimon-table">
          <tbody className="tm-h">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Country</th>
              <th>Action</th>
            </tr>
          </tbody>
          <tbody className="tm-c">
            {this.state.pageNumber === undefined
              ? this.state.tutor.slice(0, 5).map((res) => (
                  <tr key={res.id}>
                    <td>
                      {res.name}
                      {res.contact_us === true ? (
                        <p style={{ color: "grey", fontWeight: "bold" }}>
                          (Contact)
                        </p>
                      ) : null}
                    </td>
                    <td>{res.email}</td>
                    <td>{res.phone}</td>
                    <td>{res.country}</td>
                    <Popover
                      placement="right"
                      content={
                        <div
                          className="tm-e"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="contained"
                            onClick={this.showModalEdit}
                            color="default"
                          >
                            Edit
                          </Button>
                          <Modal
                            title="Edit surveyor
                        "
                            visible={this.state.visibleEdit}
                            onOk={() => this.handleOkEdit(res.id)}
                            onCancel={this.handleCancelEdit}
                            width="900px"
                          >
                            <div className="course-create-modal">
                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="text"
                                name="name"
                                placeholder="Name"
                                defaultValue={res.name}
                              />
                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="text"
                                name="email"
                                placeholder="Email"
                                defaultValue={res.email}
                              />

                              <input
                                className="ai"
                                onChange={this.handleChange}
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                defaultValue={res.phone}
                              />
                            </div>
                          </Modal>

                          <Popconfirm
                            placement={"rightBottom"}
                            title="Are you sure？"
                            onConfirm={() => this.deleteTest(res.id)}
                          >
                            <Button variant="contained" color="default">
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      }
                      trigger="click"
                    >
                      <td>
                        <MdMoreVert />
                      </td>
                    </Popover>
                  </tr>
                ))
              : this.state.productlistpagination.map((res) => (
                  <tr key={res.id}>
                    <td>
                      {res.name}
                      {res.contact_us === true ? (
                        <p style={{ color: "grey", fontWeight: "bold" }}>
                          (Contact)
                        </p>
                      ) : null}
                    </td>
                    <td>{res.email}</td>
                    <td>{res.phone}</td>
                    <td>{res.country}</td>
                    <Popover
                      placement="right"
                      content={
                        <div
                          className="tm-e"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="contained"
                            onClick={this.showModalEdit}
                            color="default"
                          >
                            Edit
                          </Button>
                          <Modal
                            title="Edit surveyor
                          "
                            visible={this.state.visibleEdit}
                            onOk={() => this.handleOkEdit(res.id)}
                            onCancel={this.handleCancelEdit}
                            width="1200px"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="course-create-modal">
                                <input
                                  className="ai"
                                  onChange={this.handleChange}
                                  type="text"
                                  name="name"
                                  placeholder="Name"
                                  defaultValue={res.name}
                                />
                                <input
                                  className="ai"
                                  onChange={this.handleChange}
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  defaultValue={res.email}
                                />

                                <input
                                  className="ai"
                                  onChange={this.handleChange}
                                  type="text"
                                  name="phone"
                                  placeholder="Phone"
                                  defaultValue={res.phone}
                                />
                              </div>
                              <Map
                                className="map transform"
                                id="map"
                                zoom={13}
                                center={[res.latitude, res.longitude]}
                                setView={true}
                                animate={true}
                                onClick={this.handleClick}
                              >
                                <TileLayer
                                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />

                                <div id="marker">
                                  <ReactLeafletSearch
                                    onChange={this.location}
                                    inputPlaceholder="Search your city"
                                    position="topleft"
                                  />

                                  {this.state.latitude !== undefined ? (
                                    this.state.currentPos && (
                                      <Marker
                                        position={this.state.currentPos}
                                        draggable={true}
                                      >
                                        <Popup position={this.state.currentPos}>
                                          Current location:
                                        </Popup>
                                      </Marker>
                                    )
                                  ) : (
                                    <Marker
                                      draggable
                                      position={[res.latitude, res.longitude]}
                                    >
                                      <Popup>Current location: </Popup>
                                    </Marker>
                                  )}
                                </div>
                              </Map>
                            </div>
                          </Modal>

                          <Popconfirm
                            placement={"rightBottom"}
                            title="Are you sure？"
                            onConfirm={() => this.deleteTest(res.id)}
                          >
                            <Button variant="contained" color="default">
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      }
                      trigger="click"
                    >
                      <td>
                        <MdMoreVert />
                      </td>
                    </Popover>
                  </tr>
                ))}
            <Modal
              title="Add an surveyor"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="1200px"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="course-create-modal">
                  <input
                    className="ai"
                    onChange={this.handleChange}
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={this.state.name}
                  />
                  <input
                    className="ai"
                    onChange={this.handleChange}
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={this.state.username}
                  />
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a country"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.countrylist.map((res) => (
                      <Option value={res.name.common}>{res.name.common}</Option>
                    ))}
                  </Select>
                  <input
                    className="ai"
                    onChange={this.handleChange}
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                  />
                  <input
                    className="ai"
                    onChange={this.handleChange}
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={this.state.phone}
                  />
                  <input
                    className="ai"
                    onChange={this.handleChange}
                    type="text"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                  />
                  <Switch
                    style={{ width: "5vh" }}
                    onChange={this.onChangeSwitch}
                  />
                  <p>Turn this on if you want to add this to contact screen</p>
                </div>
                <div>
                  {this.state.checkvalue === true ? (
                    <div>
                      <input
                        className="ai"
                        onChange={this.handleChange}
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={this.state.address}
                      />
                      <Map
                        className="map transform"
                        id="map"
                        zoom={this.state.zoom}
                        center={positionCarIcon}
                        setView={true}
                        animate={true}
                        onClick={this.handleClick}
                      >
                        <TileLayer
                          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        <div id="marker">
                          <ReactLeafletSearch
                            onChange={this.location}
                            inputPlaceholder="Search your city"
                            position="topleft"
                          />

                          {this.state.currentPos && (
                            <Marker
                              position={this.state.currentPos}
                              draggable={true}
                            >
                              <Popup position={this.state.currentPos}>
                                Current location:
                              </Popup>
                            </Marker>
                          )}
                        </div>
                      </Map>
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal>
          </tbody>
        </table>
        <Pagination
          defaultCurrent={1}
          total={this.state.paginationLength}
          onChange={this.pagination}
          disabled={this.state.tutor.length < 5 ? true : false}
        />
      </div>
    );
  }
}
