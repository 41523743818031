import React, { Component } from "react";
import { connect } from "react-redux";
import { signOut } from "../../actions/authActions";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";

class Navbar extends Component {
  current = () => {
    localStorage.setItem("loc", "1");
  };
  render() {
    return (
      <div className="navbar">
        <div className="navbar-buttons">
          <NavLink
            onClick={this.current}
            activeClassName="navbar-active"
            to="/blog"
          >
            <Button
              onClick={this.current}
              variant="contained"
              component="span"
              className="poppinsr"
            >
              <p>Manage Blog</p>
            </Button>
          </NavLink>
          <NavLink
            activeClassName="navbar-active"
            onClick={this.current}
            to="/surveyor"
          >
            <Button variant="contained" component="span" className="poppinsr">
              <p>Manage Surveyors</p>
            </Button>
          </NavLink>
          <NavLink
            activeClassName="navbar-active"
            onClick={this.current}
            to="/career"
          >
            <Button variant="contained" component="span" className="poppinsr">
              <p>Manage Careers</p>
            </Button>
          </NavLink>
          <NavLink
            activeClassName="navbar-active"
            onClick={this.current}
            to="/news"
          >
            <Button variant="contained" component="span" className="poppinsr">
              <p>Manage Service</p>
            </Button>
          </NavLink>
          <NavLink
            activeClassName="navbar-active"
            onClick={this.current}
            to="/carousel"
          >
            <Button variant="contained" component="span" className="poppinsr">
              <p>Carousel</p>
            </Button>
          </NavLink>
          <NavLink
            activeClassName="navbar-active"
            onClick={this.current}
            to="/files"
          >
            <Button variant="contained" component="span" className="poppinsr">
              <p>Files</p>
            </Button>
          </NavLink>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      dispatch(signOut());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
