import axios from "axios";
import setAuthToken from "./utils/setAuthToken";
import { SET_ERRORS } from "./commonActionType";
import { API_URL } from "../actions/urlConfig";
import { notification } from "antd";

export const SET_CURRENT_USER_DATA = "SET_CURRENT_USER_DATA";

// Login - Get User Token
export const signIn = (userData) => (dispatch) => {
  axios
    .post(`${API_URL}/authentication/login/`, userData)
    .then((res) => {
      console.log(res, "auth error");
      // Set the token
      const token = res.data.data.access;

      // Set token to ls
      localStorage.setItem("AdminToken", token);

      console.log(token, "token");

      // Set token to Auth header
      setAuthToken(token);

      // Set user
      dispatch(setCurrentUser(token));
    })
    .catch((error) => {
      notification.open({
        message: "Failed",
        description: "Some error occured",
        onClick: () => {
          window.location.reload();
          console.log("Notification Clicked!");
        },
      });
      console.log(error.response, "ERRR");
    })
    .catch(
      (err) => console.log(err, "err"),
      dispatch({
        type: SET_ERRORS,
        payload: null,
      })
    );
};

// Set logged in user
export const setCurrentUser = (token) => {
  return {
    type: SET_CURRENT_USER_DATA,
    payload: token,
  };
};

// Log user out
export const signOut = () => (dispatch) => {
  // Clear localStorage
  localStorage.clear();

  // Remove token from axios
  setAuthToken();

  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
