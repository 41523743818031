import React, { Component } from "react";
import NewsMain from "./NewsContents/NewsMain";


export default class News extends Component {
  state = {
    newValue: "",
  };
  componentDidMount = () => {
    const current = localStorage.getItem("loc");
    console.log(current, "current value");
    if (current === null) {
      this.setState({ newValue: "1" });
    } else {
      this.setState({ newValue: current });
    }
  };
  handleChange = (newValue) => {
    console.log(newValue);
    this.setState({ newValue: newValue });
  };

  render() {
    return (
      <div className="home">
        <NewsMain />
      </div>
    );
  }
}
