import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../../actions/urlConfig";
import GridLoader from "react-spinners/GridLoader";
import { notification } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const admin_token = localStorage.getItem("AdminToken"); //Get token value from localstorage
const config = {
  headers: {
    Authorization: `Bearer ${admin_token}`,
    "Content-type": "application/json",
  },
};
export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }
  state = {
    saveBtn: true,
    bimg: "",
    banner: [],
    value: "",
    loader: false,
    imageB: "",
    imageFinal: "",
    src: null,
    header_name: "",
    header_image: "",
  };

  componentDidMount = () => {
    if (window.localStorage) {
      if (!localStorage.getItem("firstLoad1")) {
        localStorage["firstLoad1"] = true;
        window.location.reload();
      } else localStorage.removeItem("firstLoad1");
    }
    console.log(admin_token);
    axios.get(`${API_URL}/carousel/view/`).then((res) => {
      console.log(res.data.data);
      this.setState({ banner: res.data.data });
    });

    localStorage.setItem("loc", "2");
  };
  focusChange = () => {
    this.setState({
      saveBtn: false,
    });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      saveBtn: false,
    });
  };

  onSelectFile = (event) => {
    this.setState(
      {
        // file: URL.createObjectURL(event.target.files[0]),
        header_image: event.target.files[0],
        saveBtn: true,
        header_name: event.target.files[0].name,
      },
      () => {
        console.log(event.target.files[0], "image");
      }
    );
  };

  //Posting the banner content

  saveBanner = () => {
    const formData = new FormData();
    formData.append("image", this.state.header_image);
    this.setState({ loader: true });

    axios
      .post(`${API_URL}/carousel/create/`, formData, config)
      .then((res) => {
        this.setState({ loader: false });
        notification.open({
          message: "Success",
          description: "Added carousel image",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        setTimeout(function () {
          window.location.reload();
        }, 1500);

        console.log(res, "banner-add");
      })
      .catch((er) => {
        console.log(er.response, "error");
      });
  };
  deleteCarousel = (id) => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      headers: { Authorization: `Bearer ${admin_token}` },
    });
    axiosInstance({
      method: `post`,
      url: `${API_URL}/carousel/delete/${id}/`,
      headers: { Authorization: `Bearer ${admin_token}` },
    })
      .then((res) => {
        this.setState({ loader: false });
        notification.open({
          message: "Success",
          description: "Deleted carousel image",
          onClick: () => {
            window.location.reload();
            console.log("Notification Clicked!");
          },
        });
        this.componentDidMount();
        console.log(res, "banner-add");
      })
      .catch((er) => {
        console.log(er.response, "error");
      });
  };

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };
    return (
      <div className="home">
        <div className="loader">
          <GridLoader color={"#947D02"} loading={this.state.loader} />
        </div>
        <div className="crop-n-add-c">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className="sub-head">Home Banner</p>

            <input
              id="contained-button-file"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={this.onSelectFile}
            />

            <p>{this.state.imageB}</p>

            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                HERO IMAGE UPLOAD
              </Button>
            </label>
            {/* {this.state.banner.image_mobile === "" ? (
              <p>No image uploaded</p>
            ) : (
              <div>
                <p>Already uploaded </p>
                <img src={this.state.banner.image_mobile}></img>
              </div>
            )} */}
            <p>{this.state.header_name}</p>
            <Button
              variant="contained"
              color="secondary"
              component="span"
              onClick={this.saveBanner}
            >
              Save
            </Button>
          </div>
        </div>
        <Slider
          arrows="false"
          autoplay
          {...settings}
          style={{ marginTop: "10vh" }}
        >
          {this.state.banner.map((res) => (
            <div
              style={{
                height: "40vh",
                width: "80vw",
              }}
            >
              <img
                style={{ width: "70vw", height: "40vh", objectFit: "cover" }}
                src={res.image}
              />
              <button onClick={() => this.deleteCarousel(res.id)}>
                Delete
              </button>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
