import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./Auth/Login";
import Navbar from "./Components/Navigation/Navbar";
import Blog from "./Components/Pages/Blog";
import News from "./Components/Pages/News";
import Header from "./Components/Navigation/Header";
import EmployeeMain from "./Components/Pages/Employee/EmployeeMain";
import Careermain from "./Components/Pages/Careers/CareerMain";
import Carousel from "./Components/Pages/Carousel";
import EditBlog from "./Components/Pages/BlogContents/EditBlog";
import CreateBlog from "./Components/Pages/BlogContents/CreateBlog";
import ExpandedBlog from "./Components/Pages/BlogContents/ExpandedBlog";
import EditNews from "./Components/Pages/NewsContents/EditNews";
import CreateNews from "./Components/Pages/NewsContents/CreateNews";
import ExpandedNews from "./Components/Pages/NewsContents/ExpandedNews";
import FileList from "./Components/Pages/Files/FileList";

class App extends Component {
  componentDidMount() {
    console.log(this.props.isAuthenticated, "auth");
    localStorage.setItem("loc", "1");
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          {this.props.isAuthenticated ? <Navbar /> : null}
          {this.props.isAuthenticated ? <Header /> : null}

          {!this.props.isAuthenticated ? <Redirect to="/" /> : null}
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/surveyor" component={EmployeeMain} />
            <Route exact path="/blog/create" component={CreateBlog} />
            <Route exact path="/blog/edit/:special" component={EditBlog} />
            <Route exact path="/blog/view/:blog" component={ExpandedBlog} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/create" component={CreateNews} />
            <Route exact path="/blog/edit/:special" component={EditNews} />
            <Route exact path="/news/view/:blog" component={ExpandedNews} />
            <Route exact path="/career" component={Careermain} />
            <Route exact path="/carousel" component={Carousel} />
            <Route exact path="/files" component={FileList} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
